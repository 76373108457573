import http from '@/services/api.js';

class apiDashboard {

    // retorna a lista de pendencias
    getCaixaResponderPesquisa() {
      return http.get(`/dashboard/fornecedores`);
    }

}

export default new apiDashboard();