export default {

    data() {
        return {
            menu: [{
                id: 1,
                label: "Admin",
                nodes: [{
                    id: 1 - 1,
                    label: "Assets",
                    nodes: [{
                        id: 1 - 1 - 1,
                        label: "CSS",
                        nodes: [{
                            id: 1 - 1 - 1 - 1,
                            label: "Css One",
                            checked: true,
                        },
                        {
                            id: 1 - 1 - 1 - 2,
                            label: "Css Two"
                        }
                        ]
                    },
                    {
                        id: 1 - 1 - 2,
                        label: "Js",
                        nodes: [{
                            id: 1 - 1 - 2 - 1,
                            label: "Js One"
                        },
                        {
                            id: 1 - 1 - 2 - 2,
                            label: "Js Two"
                        }
                        ]
                    },
                    {
                        id: 1 - 1 - 3,
                        label: "Scss",
                    },
                    ],
                },
                {
                    id: '1-2',
                    label: "Default",
                    nodes: [{
                        id: '1-2-1',
                        label: "Dashboard"
                    },
                    {
                        id: '1-2-2',
                        label: "Typography"
                    }
                    ]
                }
                ],
            },
            {
                id: 17,
                label: "index file"
            }
            ]
        }
    },
}