import { useToast } from "vue-toastification";
const toast = useToast();

function textarea_limite() {
  const teclasPermitidas = [8, 9, 37, 38, 39, 40, 46, 17, 67, 86, 65];

  const inputs = document.querySelectorAll("textarea");
  inputs.forEach(input => {
    // Verificar se o elemento possui a classe "consulta"
    if (input.closest(".consulta")) {
      input.setAttribute("readonly", true);
      return; // Ignorar elementos com a classe "consulta"
    }
    // Verificar se o elemento possui a classe "contador"
    if (!input.closest(".contador")) {
      return; // Ignorar elementos sem a classe "contador"
    }

    const limite = parseInt(input.dataset.limite);

    input.setAttribute("maxlength", limite);

    const formGroup = input.closest(".contador");

    // Adiciona um ouvinte de evento para criar o contador quando o campo recebe o foco
    input.addEventListener("focus", () => {
      const labelSpan = document.createElement("span");
      labelSpan.classList.add("limite"); // Adiciona a classe "limite" ao span
      labelSpan.style.float = "right";

      // Adiciona o contador como irmão do input
      input.parentNode.insertBefore(labelSpan, input.nextSibling);

      function updateCharCount() {
        const qtd = input.value.length;
        labelSpan.innerHTML = `${qtd} / ${limite}`;
      }

      updateCharCount(); // Atualiza o contador quando o campo recebe o foco

      input.addEventListener("input", () => {
        updateCharCount();
      });

      // Adiciona um ouvinte de evento para ocultar o contador quando o campo perde o foco
      input.addEventListener("blur", () => {
        labelSpan.remove();
      });

      input.addEventListener("paste", e => {
        const qtd = e.clipboardData.getData("text/plain").length;
        if (qtd > limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!\nSeu texto não foi copiado integralmente.");
        }
        updateCharCount();
      });

      input.addEventListener("keydown", e => {
        const qtd = input.value.length;
        if (teclasPermitidas.includes(e.keyCode)) {
          return;
        }
        if (qtd >= limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!");
          e.preventDefault();
          return;
        }
      });
    });
  });
}

function input_limite() {
  const teclasPermitidas = [8, 9, 37, 38, 39, 40, 46, 17, 67, 86, 65];

  const inputs = document.querySelectorAll("input");
  inputs.forEach(input => {
    // Verificar se o elemento possui a classe "consulta"
    if (input.closest(".consulta")) {
      input.setAttribute("readonly", true);
      return; // Ignorar elementos com a classe "consulta"
    }
    // Verificar se o elemento possui a classe "contador"
    if (!input.closest(".contador")) {
      return; // Ignorar elementos sem a classe "contador"
    }

    const limite = parseInt(input.dataset.limite);

    input.setAttribute("maxlength", limite);

    const formGroup = input.closest(".contador");

    // Adiciona um ouvinte de evento para criar o contador quando o campo recebe o foco
    input.addEventListener("focus", () => {
      const labelSpan = document.createElement("span");
      labelSpan.classList.add("limite"); // Adiciona a classe "limite" ao span
      labelSpan.style.float = "right";

      // Adiciona o contador como irmão do input
      input.parentNode.insertBefore(labelSpan, input.nextSibling);

      function updateCharCount() {
        const qtd = input.value.length;
        labelSpan.innerHTML = `${qtd} / ${limite}`;
      }

      updateCharCount(); // Atualiza o contador quando o campo recebe o foco

      input.addEventListener("input", () => {
        updateCharCount();
      });

      // Adiciona um ouvinte de evento para ocultar o contador quando o campo perde o foco
      input.addEventListener("blur", () => {
        labelSpan.remove();
      });

      input.addEventListener("paste", e => {
        const qtd = e.clipboardData.getData("text/plain").length;
        if (qtd > limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!\nSeu texto não foi copiado integralmente.");
        }
        updateCharCount();
      });

      input.addEventListener("keydown", e => {
        const qtd = input.value.length;
        if (teclasPermitidas.includes(e.keyCode)) {
          return;
        }
        if (qtd >= limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!");
          e.preventDefault();
          return;
        }
      });
    });
  });
}

function formatarDocumento(documento) {
  // Limpa todos os caracteres não numéricos
  documento = documento.replace(/\D/g, "");

  // Verifica se é CPF (11 dígitos) ou CNPJ (14 dígitos)
  if (documento.length === 11) {
    // Aplica a máscara de CPF
    documento = documento.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      "$1.$2.$3-$4"
    );
  } else if (documento.length === 14) {
    // Aplica a máscara de CNPJ
    documento = documento.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    // Caso contrário, retorna o documento sem formatação
    return documento;
  }

  return documento;
}

const formatarDataHora = valor => {
  let dateObject;

  // Se valor é uma string, tenta converter para Date
  if (typeof valor === "string") {
    dateObject = new Date(valor);

    // Verifica se a conversão foi bem-sucedida
    if (!isNaN(dateObject.getTime())) {
      // Se foi bem-sucedida, formata a data
      return dateObject.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
      });
    }
  }

  // Se não for uma string ou a conversão falhar, retorna o valor original
  return valor;
};

const formatarDataHoraListagem = value => {
  const date = new Date(value);
  return date
    .toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    })
    .replace(",", "");
};

const formatarData = valor => {
  let dateObject;

  // Se valor é uma string, tenta converter para Date
  if (typeof valor === "string") {
    dateObject = new Date(valor);

    // Verifica se a conversão foi bem-sucedida
    if (!isNaN(dateObject.getTime())) {
      // Se foi bem-sucedida, formata a data
      return dateObject.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
      }).replace(',', '');
    }
  }

  // Se não for uma string ou a conversão falhar, retorna o valor original
  return valor;
};


function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

  return JSON.parse(jsonPayload);
}

function removePlaceholder(route) {
  // console.log('route.query.consulta', route.query.consulta);
  if (route.query.consulta) {
    const element = document.querySelectorAll('[placeholder]');

    element.forEach(function (element) {
      // element.removeAttribute('placeholder');
      element.setAttribute('placeholder', 'Não informado');
    });
  }
}

// função para ir para as pendencias
function scrollPendencias(event, index) {
  document.querySelectorAll("a[data-scroll-to]").forEach(link => {
    link.addEventListener("click", clickEvent => {
      clickEvent.preventDefault();
      document.querySelector("#panel-pendencias").scrollIntoView({ behavior: 'smooth' });
    });
  });
}

const formatarCnpj = () => ({
  get: () => cnpj.value,
  set: newValue => {
    // Remove caracteres não numéricos
    const cleanCnpj = newValue.replace(/\D/g, "");

    // Verifica se o CNPJ tem o tamanho correto
    if (cleanCnpj.length === 14) {
      // Aplica a máscara (99.999.999/9999-99)
      cnpj.value = cleanCnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      );

    } else {
      // CNPJ inválido, exibe mensagem de erro
      cnpj.value = newValue;

    }
  }
});

const formatarNumero = function (valor) {
  // Verifica se o valor é numérico
  if (isNaN(valor)) {
    return "Valor inválido";
  }

  // Converte o valor para número
  valor = parseFloat(valor);

  // Formata o número para o formato brasileiro
  const numeroFormatado = new Intl.NumberFormat('pt-BR').format(valor);

  return numeroFormatado;
};

const formatarMoeda = function (valor) {
  // Verifica se o valor é null ou undefined
  if (valor === null || valor === undefined) {
    return "Valor inválido";
  }
  // Verifica se o valor é numérico
  if (isNaN(valor)) {
    return "Valor inválido";
  }

  // Converte o valor para número
  valor = parseFloat(valor);

  // Define as opções de formatação para duas ou quatro casas decimais
  let options = {
    style: 'currency',
    currency: 'BRL'
  };

  // Verifica as casas decimais do valor
  const valorString = valor.toFixed(4);
  const [inteiro, decimal] = valorString.split('.');

  if (decimal.slice(2) === "00") {
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  } else {
    options.minimumFractionDigits = 4;
    options.maximumFractionDigits = 4;
  }

  // Formata o número para o formato de moeda brasileira
  const numeroFormatado = new Intl.NumberFormat('pt-BR', options).format(valor);

  return numeroFormatado;
};

const getDia = function () {
  // Obtém a data atual
  const today = new Date();
  // Formata a data no formato aceito pelo input datetime-local (YYYY-MM-DDTHH:MM)
  const formattedToday = today.toISOString().slice(0, 16);
  return formattedToday;
}

const temporizador = function (datainicio, datafim, callback) {
  const end = new Date(datafim).getTime();
  let timerInterval;

  function updateTimer() {
    const now = new Date().getTime();
    if (now >= end) {
      callback("Tempo Encerrado!", false);
      clearInterval(timerInterval);
      console.log('encerrou');
      return;
    }

    let distance = end - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let timerString = '';
    if (days > 0) {
      timerString += `${days}d `;
    }
    if (hours > 0 || timerString !== '') {
      timerString += `${hours}h `;
    }
    if (minutes > 0 || timerString !== '') {
      timerString += `${minutes}m `;
    }
    timerString += `${seconds}s`;

    // Verificar se o tempo restante é menor que 300 segundos
    const isBlinking = distance < 300000; // 300000 ms = 300 s

    callback(timerString.trim(), isBlinking);
  }

  updateTimer();
  timerInterval = setInterval(updateTimer, 1000);
};

const minDate = function () {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const converteDate = function (data, fields) {
  return data.map(d => {
    fields.forEach(field => {
      if (d[field]) {
        d[field] = new Date(d[field]); // Certifique-se de que a data seja um objeto Date
      }
    });
    return d;
  });
};

const selecionarTexto = (valor) => {

  // Construa a classe dinâmica com base no valor
  const className = `.textoselecionado_${valor}`;
  const inputElement = document.querySelector(className + ' input');

  if (inputElement) {
    inputElement.select(); // Seleciona todo o texto no campo
  }
};


export { textarea_limite, input_limite, parseJwt, removePlaceholder, formatarDataHora, formatarData, scrollPendencias, formatarDocumento, formatarCnpj, formatarNumero, formatarMoeda, getDia, temporizador, minDate, converteDate, selecionarTexto, formatarDataHoraListagem };