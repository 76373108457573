import axios from "axios";

let yourJWTToken = '' 

const instance = axios.create({
  //baseURL: "https://64e80496b0fd9648b7906f02.mockapi.io/api/v1",
  baseURL:"https://vemlicitar.com.br/api/",
  //baseURL:"http://127.0.0.1:8000/api/",
 //baseURL:"http://10.3.14.230:8081/api/",
  headers: {
    "Content-type": "application/json", 
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('vemlicitar_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Adicionar cabeçalho para solicitações FormData
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  (error) => {
    if (error.response && error.response.status === 422) {
      // Redirecionar para a página de login se o token expirar ou for inválido
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default instance;
