<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" src="@/assets/images/dashboard/profile.png" alt="" />
      <div class="media-body">
        <span>{{ userName }}</span>
        <p class="mb-0 font-roboto">
          {{ userid }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <vue-feather type="user"></vue-feather><span>Perfil</span>
      </li>
      <li>
        <vue-feather type="mail"></vue-feather><span>Inbox</span>
      </li>
      <li>
        <vue-feather type="file-text"></vue-feather><span>Taskboard</span>
      </li>
      <li>
        <vue-feather type="settings"></vue-feather><span>Configurações</span>
      </li>
      <li @click="logout">
        <vue-feather type="log-in"></vue-feather><span>Desconectar</span>
      </li>
    </ul>
  </li>
</template>

<script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";
  import http from '@/services/api.js';

  export default {
    name: 'Profile',
    computed: {
      userName() {
        // Obtem o nome do usuário armazenado no localStorage
        return localStorage.getItem('vemlicitar_user_name') || 'Nome do Usuário';
      },
      userid() {
        // Obtem o nome do usuário armazenado no localStorage
        return localStorage.getItem('vemlicitar_id_user') || ' ';
      },
    },
    methods: {
      logout() {
        // Limpar o token do localStorage
        localStorage.removeItem('vemlicitar_token');
        localStorage.removeItem('vemlicitar_user_name');
        localStorage.removeItem('vemlicitar_id_user'); 
        // Armazenar mensagem de logout no localStorage
        localStorage.setItem('logout_message', 'success:Sua sessão com o sistema foi encerrada.')
        // Redirecionar para a página de login
        this.$router.push('/login');
      }
    },
  };
</script>
