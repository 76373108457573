<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 b-center bg-size" :style="{ backgroundImage: 'url(' + require('../../assets/images/login/1.jpg') + ')' }" style="background-size: cover; background-position: center center; display: block;">
          <img class="bg-img-cover bg-center" src="../../assets/images/login/1.jpg" alt="forgetpage" style="display: none;" />
        </div>
        <div class="col-xl-5 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo text-start">
                  <!-- <img class="img-fluid for-light" src="../../assets/images/logo/isologo.png" alt="forgetpage" />
                  <img class="img-fluid for-dark" src="../../assets/images/logo/logo_dark.png" alt="forgetpage" /> -->
                </a>
              </div>
              <div class="login-main ">
                <form class="theme-form" @submit.prevent="forget">
                  <h4>Lembrete de Senha</h4>
                  <p>Informe seu e-mail para o envio do lembrete</p>
                  <div class="form-group">
                    <label class="col-form-label">e-mail</label>
                    <input class="form-control" type="email" name="email" v-model="email" required maxlength="100" placeholder="Informe seu e-mail" />
                  </div>                   
                  <div class="form-group mb-0">
                    <router-link class="link" to="/login"> Retornar ao Acesso</router-link>
                    <br><br>                   
                    <button class="btn btn-primary btn-block w-100" type="submit">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
      };
    },
    methods: {
      forget() {
        // Enviar o e-mail ao Laravel para gerar o token de lembrete de senha
        axios.post('/auth/forgot', {
          email: this.email,
        })
        .then(response => {
          // Lógica de tratamento de sucesso
          console.log('Token de lembrete de senha enviado com sucesso');
        })
        .catch(error => {
          // Lógica de tratamento de erro
          console.error('Erro ao enviar o token de lembrete de senha:', error);
        });
      },
    },
  };
  </script>
  