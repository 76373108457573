
import { createStore } from 'vuex';

// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';
import apiUsuario from '@/services/apiUsuario';
import CryptoJS from 'crypto-js';
// import chat from './modules/chat';
// import product from './modules/product';
// import email from './modules/email';
// import todo from './modules/todo';
// import firebase_todo from './modules/firebase_todo';
// import contact from './modules/contact';
// import jobs from './modules/jobs';
// import courses from './modules/courses';
// import common from './modules/common';
// import tags from './modules/tags';
// import calendar from './modules/calendar'
// import { alert } from './modules/alert';
// import { authentication } from './modules/authentication';
// import { users } from './modules/users';

const ENCRYPTION_KEY = '653c743da10dc93e50e51';

function encryptData(data) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  return encryptedData;
}

function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

export default createStore({
  state: {
    langIcon: '',
    langLangauge: '',
    isActive: false,
    competenciasUsuario: [],
    pollingIntervalId: null,
    userid: null,
  },

  getters: {
    langIcon: (state) => { return state.langIcon },
    langLangauge: (state) => { return state.langLangauge },
    competenciasUsuario: (state) => state.competenciasUsuario,
    getUserId: (state) => state.userid,
  },
  mutations: {
    setUserId(state, userid) {       
      state.userid = localStorage.getItem('vemlicitar_id_user'); 
    },

    changeLang(state, payload) {
      localStorage.setItem('currentLanguage', payload.id);
      localStorage.setItem('currentLanguageIcon', payload.icon);
      state.langIcon = payload.icon || 'flag-icon-us'
      state.langLangauge = payload.id || 'EN'

    },
    change(state) {
      state.isActive = !state.isActive
    },
    setCompetenciasUsuario(state, competencias) {
      state.competenciasUsuario = competencias;
      // Armazenar no localStorage
      localStorage.setItem('competenciasUsuario', encryptData(competencias));
    },

    // Adicione uma mutação para inicializar o estado a partir da API em vez do localStorage
    initializeStateFromLocalStorage(state) {
      const storedCompetenciasUsuario = localStorage.getItem('competenciasUsuario');
      if (storedCompetenciasUsuario) {
        state.competenciasUsuario = decryptData(storedCompetenciasUsuario);
      }
    },

    initializeStateFromApi(state) {
      // Chame a ação para buscar as competências na API
      this.dispatch('fetchCompetenciasUsuario');
    },
  },

  actions: {

    updateUserId({ commit }, userid) {
      commit('setUserId', userid);
    },    

    async fetchCompetenciasUsuario({ commit }) {
      try {
        // Faz a chamada à API
        const data = await apiUsuario.getAllCompetencias();

        const competencias = data.itens.map(item => ({
          manter_orgao: item.manter_orgao,
          manter_perfil: item.manter_perfil,
          manter_grupotrabalho: item.manter_grupotrabalho,
          manter_unidade_administrativa: item.manter_unidade_administrativa,
          manter_usuario: item.manter_usuario,
          manter_catalogacao: item.manter_catalogacao,
          manter_processo: item.manter_processo,
          catalogar_item: item.catalogar_item,
          consultar_catalogacao: item.consultar_catalogacao,
          consultar_unidade_administrativa: item.consultar_unidade_administrativa,
          consultar_perfil: item.consultar_perfil,
          consultar_usuario: item.consultar_usuario,
          consultar_grupotrabalho: item.consultar_grupotrabalho,
          consultar_catalogo: item.consultar_catalogo,
          consultar_orgao: item.consultar_orgao,
          consultar_processo: item.consultar_processo,
          consultar_licitacoes: item.consultar_licitacoes,
          consultar_contratacoes: item.consultar_contratacoes,
          consultar_registro_preco: item.consultar_registro_preco,
          planejar_processo: item.planejar_processo,
          aprovar_processo: item.aprovar_processo,
          responder_registro_preco: item.responder_registro_preco,
          emitir_parecer_processo: item.emitir_parecer_processo,
          emitir_parecer_licitacao: item.emitir_parecer_licitacao,
          unidade_adm_iduadm: item.unidade_adm_iduadm,
          idorgao: item.idorgao,
          responder_pesquisa: item.responder_pesquisa,
          participar_dispensa: item.participar_dispensa,
          participar_licitacoes: item.participar_licitacoes,
          manter_usuario_fornecedor: item.manter_usuario_fornecedor,
          idfornecedor: item.idfornecedor

        }));
        // Chama a mutação para atualizar o estado
        commit('setCompetenciasUsuario', competencias);
      } catch (error) {
        console.error('Erro ao buscar competências do usuário:', error);
      }
    },

    setLang({ commit }, payload) {
      commit('changeLang', payload);

    },
    // Adicione uma ação para inicializar o estado a partir do localStorage
    initializeStateFromApi({ commit }) {
      commit('initializeStateFromApi');
    },

  },
  modules: {
    layout,
    menu,

  },

});

