import http from '@/services/api.js';

class apiUsuarios {
  getAll() {
    return http.get("/gestao/cadastros/usuarios")
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
  }
  getAllCompetencias() {
    return http.get("/gestao/cadastros/usuarios/competencias")
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
  }


  get(id) {
    return http.get(`/gestao/cadastros/usuarios/${id}`);
  }

  gethistorico(id) {
    return http.get(`/gestao/cadastros/usuarios-historico/${id}`);
  }
  getPerfis(id) {
    return http.get(`/gestao/cadastros/usuarios/${id}/perfis`);
    
  }
  getTodosPerfisUsuario(id) {
    return http.get(`gestao/cadastros/usuarios/${id}/perfis/all`);
    
  }
  updatePerfisUsuario(id, data) {
    return http.put(`gestao/cadastros/usuarios/${id}/perfis`, data); 
    
  }

  create(data) {
    return http.post("/gestao/cadastros/usuarios", data);
  }

  update(id, data) {
    return http.put(`/gestao/cadastros/usuarios/${id}`, data);
  }

  updateStatus(id, data) {
    return http.put(`/gestao/cadastros/usuarios/status/${id}`, data);
  }

  getUnidadesAdministrativas(id) {
    return http.get(`/gestao/cadastros/orgaos/${id}/unidades-administrativas`);
  }
  getUnidadesAdministrativasProcesso(id,competencia) {
    return http.get(`/gestao/cadastros/usuarios/${id}/unidades-administrativas/competencia/${competencia}`);
  }

  getPerfisUnidadesAdministrativas(id, idundadm) {
    return http.get(`/gestao/cadastros/usuarios/${id}/perfis/unidades-administrativas/${idundadm}`);
  }

  delete(id) {
    return http.delete(`/gestao/cadastros/usuarios/${id}`);
  }

  deleteAll() {
    return http.delete(`/gestao/cadastros/usuarios`);
  }

  findByTitle(title) {
    return http.get(`/gestao/cadastros/usuarios?title=${title}`);
  }
}

export default new apiUsuarios();