<template>
  <div class="col-sm-6 box-col-6">
    <div class="row">
      <h5>Caixa de Entrada</h5>
      <div v-if="validaCaixaEntradaFornecedor(exibeCaixaEntradaForn)" class="card-caixa-entrada">
        <div class="col-md-6 caixa-entrada" v-for="caixa in caixaEntrada" :key="caixa">
          <div class="card widget-1">
            <a :href="caixa.link">
              <div class="card-body">
                <div class="widget-content">
                  <div :class="caixa.widgetClass">
                    <div class="bg-round">
                      <svg class="svg-fill">
                        <use
                          :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${caixa.svgIcon1}`"
                        />
                      </svg>
                      <svg class="half-circle svg-fill">
                        <use
                          :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${caixa.svgIcon2}`"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <h4>{{ caixa.number }}</h4>
                    <span class="f-light">{{ caixa.title }}</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import { ref, onMounted, onUpdated, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useConfirm } from "primevue/useconfirm";
import apiDashboard from "@/services/apiDashboard";

export default defineComponent({
  name: "caixaDeEntrada",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const confirm = useConfirm();
    const store = useStore();
    let firstLoad = true;
    const caixaEntrada = ref([]);

    //Função para validar a exibição da caixa de entrada de fornecedor
    const validaCaixaEntradaFornecedor = function() {
      // const exibeCaixaEntradaForn =
      //   store.state.competenciasUsuario[0].idfornecedor === 1;
       const exibeCaixaEntradaForn = true; 
      

      return exibeCaixaEntradaForn;
    };

    // Função para obter os itens do processo
    const fetchInitialCaixaResponderPesquisa = async () => {
      try {
        const response = await apiDashboard.getCaixaResponderPesquisa();
        caixaEntrada.value = response.data.itens.map(item => ({
          widgetClass: item.widgetClass,
          svgIcon1: item.svgIcon1,
          svgIcon2: item.svgIcon2,
          number: item.number,
          title: item.title,
          link: item.link
        }));
        firstLoad = false;
        console.log("caixaEntrada", caixaEntrada.value);
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };


    const fetchCaixaResponderPesquisa = () => {
      window.Echo.channel("channeldashboardforn").listen(
        "ChannelDashboardForn",
        data => {
          try {
            if (data) {              
              fetchInitialCaixaResponderPesquisa();
              toast.clear();
              toast.warning(data.mensagem);
            } else {
              console.error(
                "Os dados do evento não estão no formato esperado:",
                data
              );
            }
          } catch (error) {
            console.error("Erro ao processar os dados do evento:", error);
          }
        }
      );
    };

    onMounted(async () => {
      fetchInitialCaixaResponderPesquisa();
      await fetchCaixaResponderPesquisa();
    });

    return {
      route,
      toast,
      caixaEntrada,
      fetchInitialCaixaResponderPesquisa,
      validaCaixaEntradaFornecedor
    };
  }
});
</script>
