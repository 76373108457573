import http   from '@/services/api.js';
import { parseJwt } from "@/assets/js/util";
 
function getUserId() {
  const token = localStorage.getItem('vemlicitar_token');
  if (token) {
    const tokenPayload = parseJwt(token);
    return tokenPayload.sub;
  }
  return null;
}

//Recupera o menu do usuario logado
const apiMenu = {
  getAll() {
    const userId = getUserId();
    if (userId) {
      return http.get(`menuprincipal`);
    } else {
      // Lidar com a ausência do userId/token
      return Promise.reject('Usuário não autenticado');
    }
  }
};

export default apiMenu;