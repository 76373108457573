<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 b-center bg-size" :style="{ backgroundImage: 'url('+ require('../../assets/images/login/vem2.jpg') +')' }" style="background-size: cover; background-position: center center; display: block;">
          <img class="bg-img-cover bg-center" src="../../assets/images/login/vem2.jpg" alt="looginpage" style="display: none;" />
        </div>
        <div class="col-xl-5 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo text-start">
                  <!-- <img class="img-fluid for-light" src="../../assets/images/logo/isologo.png" alt="looginpage" />
                  <img class="img-fluid for-dark" src="../../assets/images/logo/logo_dark.png" alt="looginpage" /> -->
                </a>
              </div>
              <div class="login-main ">
                <form class="theme-form" @submit.prevent="login">
                  <h4>Acesse o Sistema</h4>
                  <p>Informe seu CPF e senha de acesso1</p>
                  <div class="form-group">
                    <label class="col-form-label">CPF</label>
                    <input class="form-control" name="id" v-model="id" required maxlength="11" placeholder="Informe seu CPF" />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Senha</label>
                    <div class="form-input position-relative">
                      <input class="form-control"  name="password" v-model="password" required placeholder="Informe a sua senha" />
                      <div class="show-hide"><span @click.prevent="show"></span></div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <router-link class="link" to="/forget-password"> Esqueci a senha?</router-link>
                    <br><br>                   
                    <button class="btn btn-primary btn-block w-100" type="login">Entrar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";
  import http from '@/services/api.js';
  
  export default {
  name: 'Login',
  data() {
    return {
      id: '',
      password: '',
      toast: useToast(),
      logoutMessage: null,
      
    };
  },
  mounted() {
    const logoutMessage = localStorage.getItem('logout_message');
    

    if (logoutMessage !== null) {
      // Divide a mensagem usando ':' como delimitador
      const [type, message] = logoutMessage.split(':');

      // Verifica o tipo de mensagem
      if (type === 'success') { 
        this.toast.success(message); 
      } else if (type === 'error') { 
        this.toast.error(message); 
      }
      // Limpa a mensagem do localStorage para que ela não apareça novamente na próxima vez
      localStorage.removeItem('logout_message');
    }
  
  },
  methods: { 
    login() {
      // Use a instância do Axios definida no arquivo api.js (com baseURL configurado)
      http.post("/auth/login", {
          id: this.id,
          password: this.password,
      })
      .then((response) => { 
          if (response && response.data && response.data.access_token) {
              //console.log('Login bem-sucedido! Redirecionando para /dashboard...');

              const token = response.data.access_token;
              localStorage.setItem('vemlicitar_token', token);

              // Armazenar o nome do usuário no localStorage
              const userName = response.data.name;
              localStorage.setItem('vemlicitar_user_name', userName);
              const userid = response.data.id;
              localStorage.setItem('vemlicitar_id_user', userid);

              // Chame a action para atualizar o userid no Vuex
              this.$store.dispatch('updateUserId', userid);              

              this.$router.push('/dashboard');
              this.$store.dispatch('fetchCompetenciasUsuario');
          } else {
              this.toast.error("Erro ao autenticar. Tente novamente mais tarde.");
          }
      })
      .catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
              this.toast.error(error.response.data.message);
          } else {
              this.toast.error("Erro ao autenticar. Tente novamente mais tarde.");
          }
      });
      console.log('acessou o sistema')
      
    },
    show() {
      // Método para controlar a exibição da senha
      const passwordInput = document.querySelector('[name="password"]');
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
      } else {
        passwordInput.type = 'password';
      }
    },
  },
};
</script>
  