<template>
  <div class="mode">
    <svg  v-show="mixLayout == 'dark-sidebar'"
    @click="customizeMixLayout('dark-only')">
      <use href="@/assets/svg/icon-sprite.svg#moon"></use>
    </svg>
    <!-- <i
      class="fa fa-moon-o"
     
    ></i> -->
    <svg   v-show="mixLayout == 'dark-only'"
    @click="customizeMixLayout('dark-sidebar')">
      <use href="@/assets/svg/icon-sprite.svg#moon"></use>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'Mode',
    data() {
      return {
        mixLayout: 'dark-sidebar',
      };
    },
    methods: {
      customizeMixLayout(val) {
        this.mixLayout = val;
        this.$store.dispatch('layout/setLayout', {class:val});
      },
    },
  };
</script>
